<!-- Mensajes Particulares (Finder) -->

<template>
  <div class="msgPartF" v-if="loaded">

    <div class="conflex" style="justify-content:center;">
      <v-sheet :elevation="4" style="width:95%">

        <!-- Cabecera -->
        <div class="cabecera">
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader">
          </baseHeader>
        </div>


        <!-- Contenido -->
        <div class="contenedor" >
          <div class="conflex" style="justify-content:center">
            <div class="columna" style="flex-grow:1">

              <!-- Grid -->
              <div class="cab">SELECCIÓN USUARIOS</div>
              <v-sheet v-bind="$cfg.styles.marco">
                <baseGridMD
                  :store="storeName"
                  :headers="headerGrid"
                  :items-per-page="-1"
                  hide-default-footer
                  show-select
                  :multipleSelect=true
                  dense
                  @onEvent="execAccion">

                    <template v-slot:msg_privado="{item}">
                      <span style="color:rgb(6 90 157);font-weight:bold">{{ item.msg_privado }}</span>
                    </template>

                    <!-- Slot botonera Top -->
                    <template v-slot:gridTop>
                      <div class="conflex">
                        <v-select
                          style="width:20rem;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                          :items="itemsGrupos"
                          item-value="id"
                          item-text="name"
                          v-bind="$select"
                          v-model="ct.grupo[2]"
                          return-object
                          @change="changeGrupo">
                        </v-select>

                        <!-- select filtrar por departamento CTD -->
                        <v-select
                          style="width:20rem;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding-left:10px"
                          v-show="CTD"
                          :items="itemsDeptos"
                          item-value="id"
                          item-text="name"
                          v-bind="$select"
                          v-model="ct.depto[2]"
                          @change="changeDepto">
                        </v-select>

                        <!-- select filtrar por Agencia APDS -->
                        <v-select
                          style="width:20rem;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding-left:10px"
                          v-show="APDS"
                          :items="itemsAgencia"
                          item-value="id"
                          item-text="name"
                          v-bind="$select"
                          v-model="ct.age[2]"
                          @change="changeAgencia">
                        </v-select>
                      </div>
                    </template>

                </baseGridMD>
              </v-sheet>

            </div>

            <div class="columna" style="padding-left:10px">
              <div class="cab">DATOS DEL MENSAJE</div>
              <v-sheet v-bind="$cfg.styles.marco">
                <baseBtraExtra class="conflex"
                  :permExtra="permExtra"
                  :modulo="btExtCfg"
                  @onEvent="execAccion">
                </baseBtraExtra>

                <v-textarea
                  style="padding-top:10px;width:600px"
                  v-bind="$textarea"
                  v-model="ct.msg[2]"
                  :label="ct.msg[1]"
                  rows="5"
                  no-resize>
                </v-textarea>
              </v-sheet>

              <div style="margin-top:10px">
                <b>Nota:</b>

                <div class="conflex" style="margin-top:5px;align-items:center;">
                  <v-btn v-bind="$cfe.default.btra.std" disabled>
                    <v-icon v-bind="$cfe.default.btra.std.icons">
                      {{ 'mdi-content-save' }}
                    </v-icon>
                  </v-btn>

                  <span style="margin-left:5px">Guarda mensaje para mostrarlo al entrar en la aplicación.</span>
                </div>

                <div class="conflex" style="margin-top:5px;align-items:center;">
                  <v-btn v-bind="$cfe.default.btra.std" disabled>
                    <v-icon v-bind="$cfe.default.btra.std.icons">
                      {{ 'mdi-delete' }}
                    </v-icon>
                  </v-btn>

                  <span style="margin-left:5px">Elimina mensaje guardado.</span>
                </div>

                <div class="conflex" style="margin-top:5px;align-items:center;">
                  <v-btn v-bind="$cfe.default.btra.std" disabled>
                    <v-icon v-bind="$cfe.default.btra.std.icons">
                      {{ 'mdi-cellphone-message' }}
                    </v-icon>
                  </v-btn>

                  <span style="margin-left:5px">Envio mensaje SMS.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-sheet>
    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraExtra from "@/base/baseBtraExtra";

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraExtra },
    props: {},

    data() {
      return {
        stIni: {
          api: "msgPartF",
          titulo:"·Mensajes Privados",
          name:"msgPartF",
          mView:'',
          pView:[],
          preserveSelected: true
        },

        recordsSelectedSaved: [],
        itemsGrupos:[],
        itemsDeptos:[],
        itemsAgencia:[]
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // Cabecera Finder
        this.headers= [{
          name:[],
          fields:[
            { text: "Nombre", value: "name", width: "35%" },
            { text: "Mensaje", value: "msg_privado", width: "65%", slot:true }
          ]
        }];

        this.headerGrid= this.headerSet();

        // botonera
        this.btExtCfg.btnsAccion= this.btExtCfg.btnsAccion.concat([
          { accion:'save', title:'Establecer mensaje privado', btn: "guardar" },
          { accion:'delete', title:'Eliminar mensaje privado', btn: "eliminar" },
          { accion:'sms', title:'Envío SMS', btn: "sms" },
        ]);


        // array de grupos de usuarios
        this.itemsGrupos= [
          { id:'', name:'Selecciona Usuarios', mov:''},
          { id:'buscarCtds', name:'Usuarios CTD', mov:'U'},
          { id:'buscarApds', name:'Usuarios APD', mov:'A'},
          { id:'buscarJzs', name:'Usuarios COORDINADOR DE SERVICIOS', mov:'J'},
          { id:'buscarConsultas', name:'Usuarios CONSULTAS', mov:'W'}
        ];

        // array de departamentos del CTD
        this.itemsDeptos= this.$store.state.datos_iniciales.deptos;
        this.itemsDeptos.unshift({ id:"", name: "Selecciona departamento" });

        // array de Agencias APDS
        this.itemsAgencia= [
          { id:'', name:'Todos' },
          { id:'1', name:'Agencia Afecta' },
          { id:'2', name:'Directos' },
        ]
      },


      iniDataApi() {
        this.apiArgs.set= ['finder', 'setMsgIni', { mov:'', msj:'', users:[] }, this.stIni.api];
      },


      // entra al cambiar la selección del combo de usuarios
      // blanqueo records seleccionadpos y muestro datos del grupo de usuarios seleccionado
      changeGrupo() {
        this.$store.commit(this.storeName + '/recordsSelectedSet', []);
        this.refresh();
      },


      // entra al cambiar de departamento
      // filtro por departamento seleccionado
      changeDepto() {
        this.$store.commit(this.storeName + '/recordsSelectedSet', []);
        this.filterByDepto();
      },

      // filtro por departamento
      filterByDepto() {
        let records= JSON.parse(JSON.stringify(this.$store.state[this.storeName].recordsRead[0]));
        this.$store.commit(this.storeName + '/data2State', { prop:'records', value:records.filter(elem=> {
          if (!this.ct.depto[2]) return elem;
          return elem.ndep== this.ct.depto[2];
        })});
      },


      // entra al cambiar de agencia
      // filtro por el tipo de agencia seleccionada
      changeAgencia() {
        this.$store.commit(this.storeName + '/recordsSelectedSet', []);
        this.filterByAgencia();
      },

      // filtro por tipo de Agencia
      filterByAgencia() {
        let records= JSON.parse(JSON.stringify(this.$store.state[this.storeName].recordsRead[0]));
        this.$store.commit(this.storeName + '/data2State', { prop:'records', value:records.filter(elem=> {
          if (!this.ct.age[2]) return elem;
          if (this.ct.age[2]=='1') return elem.idage!= 1000;
          return elem.idage== 1000;
        })});
      },


      // recarga GRID
      async refresh() {
        // si no selecciono un grupo de usuarios blanqueo GRID y salgo
        if (!this.ct.grupo[2].id) {
          this.$store.commit(this.storeName + '/recordReadSet', [[]])
          return;
        }

        // recargo GRID con la acción indicada como id
        await this.watchAccion('filtro', this.ct.grupo[2].id);

        // filtro localmente según grupo de usuarios seleccionado
        if (this.ct.grupo[2].id=='buscarCtds') this.filterByDepto();
        if (this.ct.grupo[2].id=='buscarApds') this.filterByAgencia();
      },


      // establezco un mensaje particular en los usuarios seleccionados
      async save() {
        if (!this.validate()) return;
        this.setMsg(this.ct.msg[2]);
      },


      // elimino el mensaje particular de los usuarios seleccionados
      async delete() {
        if (!this.validateUsers()) return;
        this.setMsg('');
      },


      // llamada API para guardar / eliminar mensaje particular
      async setMsg(msj) {

        let param = { apiArg: this.apiArgs.set, args: { mov:this.ct.grupo[2].mov, msj:msj, users:this.$store.state[this.storeName].recordsSelected }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        // Pendiente: controlar error
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Mensaje modificado correctamente', 'success', 1000);


        // recargo datos Grid
        await this.refresh();
      },


      // envio SMS a los usuarios seleccionados
      sms() {
        if (!this.validate()) return;

        this.$root.$alert.open('¿ Se procede al envío del SMS a los usuarios seleccionados ?', "confirm").then(r => {
          if (r) { this.smsFin() }
        });
      },

      async smsFin() {
        // monto array con el grupo de usuarios seleccionados (A:APDS, J:JEFES DE ZONA,...) + su id
        let records= this.$store.state[this.storeName].recordsSelected;
        let mov= records.map(elem=> { return  this.ct.grupo[2].mov + elem.id });

        // envio mensaje/s
        let apiResult= await this.$store.dispatch("envio_SMS", { mov, txt:this.ct.msg[2] });
        if (apiResult.sql.error) {
          this.$root.$alert.open('Error al enviar el/los mensaje/s', 'error');
          return;
        }

        this.$root.$alert.open('Mensaje/s enviado/s correctamente', 'success');
      },


      // validación de usuarios y mensaje
      validate() {
        if (!this.validateUsers()) return false;
        if (!this.validateMsg()) return false;
        return true;
      },

      // valido que haya usuarios seleccionados
      validateUsers() {
        if (!this.$store.state[this.storeName].recordsSelected.length) {
          this.$root.$alert.open('Debe seleccionar uno o varios usuarios', 'error');
          return false;
        }

        return true
      },

      // valido que el mensaje no esté vacío
      validateMsg() {
        if (!this.ct.msg[2]) {
          this.$root.$alert.open('Debe estabelecer un mensaje inicial', 'error');
          return false;
        }

        return true
      }

    },



    computed: {
      CTD() {
        return this.ct.grupo[2].id=='buscarCtds'? true : false;
      },

      APDS() {
        return this.ct.grupo[2].id=='buscarApds'? true : false;
      },

    },


  };
</script>
